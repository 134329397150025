<template>
  <Page
    :scroll="scrollTop"
    scroll-behavior
    is-header
    @scroll="(top) => (scrollTop = top)"
  >
    <template #right>
      <van-icon
        class="u-m-l-6"
        class-prefix="iconfont"
        name="question"
        size="20"
        color="#ffffff"
        @click="isShowTipsPop = true"
      />
    </template>
    <van-sticky offset-top="1.174rem">
      <!-- 搜索框 -->
      <Search
        v-model="deviceCode"
        placeholder="请输入设备编号搜索"
        :show-action="true"
        @on-search="handleSearch"
      >
        <template #action>
          <i
            class="iconfont iconfont-shaixuan"
            @click="isShowPopRight = true"
          />
        </template>
      </Search>
    </van-sticky>
    <!-- 列表 -->
    <div class="device-list-box u-m-t-12">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDeviceListByQuery"
      >
        <template v-if="deviceList.length">
          <template v-for="device in deviceList" :key="device.deviceCode">
            <DeviceInfo :device-info="device" />
          </template>
        </template>
        <template v-else>
          <van-empty image="search" description="" />
        </template>
      </van-list>
    </div>
    <!-- 筛选 -->
    <PopRight
      v-model:visible="isShowPopRight"
      dom-key="device"
      @action="handleFilterAction"
    >
      <template #content>
        <div class="filter-box">
          <div
            v-for="(item, index) in filters"
            :key="item.label"
            class="filter-item"
          >
            <p class="filter-item-label">{{ item.label }}</p>
            <ul
              v-if="item.type === 'tag'"
              class="filter-item-value u-flex u-flex-wrap"
            >
              <li
                v-for="tag in item.value"
                :key="tag.value"
                class="filter-item-tag u-flex u-row-center"
                :class="{ selected: tag.select }"
                @click="handleSelectTag(index, tag)"
              >
                {{ tag.label }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </PopRight>
    <!-- 提示 -->
    <van-popup
      v-model:show="isShowTipsPop"
      round
      :lock-scroll="true"
      :close-on-popstate="true"
      teleport="body"
      position="center"
      close-on-click-overlay
      :style="{ width: '87%' }"
    >
      <div class="u-p-24">
        <p class="u-font-20 u-p-b-12 u-text-center">注册状态说明</p>

        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          已注册：厂商已注册设备，运营商未激活。
        </p>
        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          已激活：设备已绑定运营商。
        </p>
        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          已回收：运营商解绑设备。
        </p>
      </div>
    </van-popup>
  </Page>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  toRefs,
  onActivated,
} from "vue";
import { factoryGetDeviceListApi } from "@/api/vendor";
import Search from "@/components/Search";
import PopRight from "@/components/PopRight";
import DeviceInfo from "./components/DeviceInfo.vue";
import { vendorOptions } from "./const";
import { onBeforeRouteLeave } from "vue-router";
export default defineComponent({
  name: "VendorDevice",
  components: {
    Search,
    PopRight,
    DeviceInfo,
  },
  setup() {
    const scrollTop = ref(0);
    const deviceCode = ref("");
    const isShowPopRight = ref(false);
    const isShowTipsPop = ref(false);
    const isRender = ref(true);
    const deviceList = ref([]);
    const filters = reactive(vendorOptions);
    const query = reactive({
      page: 1,
      pageSize: 10,
      loading: false,
      finished: false,
    });
    onBeforeRouteLeave((to) => {
      if (to.name === "VendorDeviceDetail") {
        isRender.value = false;
      } else {
        isRender.value = true;
      }
    });
    onActivated(() => {
      if (isRender.value) {
        deviceCode.value = "";
        query.finished = false;
        reset();
        handleSearch();
      }
    });
    // 搜索
    const handleSearch = () => {
      query.page = 1;
      scrollTop.value = 0;
      deviceList.value = [];
      getDeviceListByQuery();
    };

    // 请求
    const getDeviceListByQuery = async () => {
      try {
        query.loading = true;
        const {
          code,
          content: { currentList, totalSize },
        } = await factoryGetDeviceListApi({
          ...filterQuery.value,
          page: query.page,
          pageSize: query.pageSize,
          deviceCode: deviceCode.value,
        });
        if (code === 200) {
          if (query.page === 1) {
            deviceList.value = currentList;
          } else {
            deviceList.value = deviceList.value.concat(currentList);
          }
          query.finished = Math.ceil(totalSize / 10) <= query.page;
          query.page++;
          query.loading = false;
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    // 选择条件
    const handleSelectTag = (idx, tag) => {
      filters[idx].value?.forEach((v) => {
        if (v.value === tag.value) {
          v.select = true;
        } else {
          v.select = false;
        }
      });
    };
    // 重置
    const reset = async () => {
      filters.forEach((item) => {
        if (item.type === "tag") {
          item.value.forEach((v, i) => {
            if (i) {
              v.select = false;
            } else {
              v.select = true;
            }
          });
        }
      });
      return Promise.resolve(true);
    };
    // 计算筛选条件
    const filterQuery = computed(() => {
      let result = {};
      filters.forEach((item) => {
        if (item.type === "tag") {
          result[item.key] = item.value.find((tag) => tag.select).value;
        }
      });
      return result;
    });
    // 操作按钮
    const handleFilterAction = async (type) => {
      if (type < 0) {
        // 重置
        await reset();
        handleSearch();
      } else {
        // 确定
        handleSearch();
      }
      isShowPopRight.value = false;
    };
    return {
      ...toRefs(query),
      scrollTop,
      isShowTipsPop,
      filters,
      deviceCode,
      deviceList,
      isShowPopRight,
      handleSearch,
      handleSelectTag,
      handleFilterAction,
      getDeviceListByQuery,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-item-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 15px;
}
.filter-item-value {
  padding-bottom: 12px;
  :deep(.van-search) {
    padding: 0;
  }
}
.filter-item-tag {
  min-width: 74px;
  height: 32px;
  background-color: #f0f2f5;
  border-radius: 4px;
  color: #373838;
  margin: 0 10px 8px 0;
  font-size: 14px;
  padding: 0 6px;
  box-sizing: border-box;
}
.selected {
  background-color: rgba(2, 122, 255, 0.2);
  color: #027aff;
}
.device-list-box {
  max-height: calc(100vh - 112px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
